import './styles/App.css';
import { Route, Routes } from 'react-router-dom';
import { Home, Product, LandingProduct, Order } from './pages';
import ReactPixel from 'react-facebook-pixel';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import useStore from './store/useStore';
import { useGeolocated } from "react-geolocated";
import { Helmet } from 'react-helmet';

const cookies = new Cookies(null, { path: '/' });

function App() {
    const dataStore = useStore((state: any) => state.store);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/store`)
        .then(({data}) => {
            // cookies.set('store', JSON.stringify(dataStore));
            useStore.setState({ store: data });
        })
        .catch((error) => {
            console.log(error);
        })
    }, [])
    
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/pixels`)
        .then(({data}) => {
            // const facebookData = data.filter((item: any) => item.name === "facebook")
            // console.log(facebookData);
            // setFacebookToken(facebookData?.[0]?.apiKey)
            const advancedMatching = undefined;
            data?.map((item: any, index: number) => {
                if (item?.apiKey !== "" && item?.apiKey.length > 10) {
                    ReactPixel.init(item?.apiKey, advancedMatching, {
                        autoConfig: true,
                        debug: true
                    });
                }
            })
            ReactPixel.pageView();
        })
        .catch((error) => console.log(error));
    }, [])

    // useEffect(() => {
    //     if (facebookToken !== "") {
    //         ReactPixel.init(facebookToken);
    //         ReactPixel.pageView();
    //         // ReactPixel.track()
    //     }
    // }, [facebookToken])

    return (
        <>
            <Helmet>
                <link rel="icon" href={dataStore?.icon && dataStore?.icon !== "" ? `${process.env.REACT_APP_API_URL_IMAGES}/${dataStore?.icon}` : "/favicon.ico"} />
                <title>{dataStore?.information?.title || ""}</title>
                <meta name="description" content={dataStore?.information?.shortDescription || ""} />
            </Helmet>
            <Routes>
                <Route index path="/" element={<Home />} />
                <Route index path="/product/:id" element={<Product />} />
                <Route index path="/:id" element={<LandingProduct />} />
                <Route index path="/order/:status/:type?/:id?/:isMany?" element={<Order />} />
            </Routes>
        </>
    );
}

export default App;
